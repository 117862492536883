export interface AllFormats {
    [type: string]: {
        [format: string]: Format;
    };
}

interface Format {
    key: string;
    title: string;
    width: number;
    height: number;
    type: string;
    sets: string[];
    category: string;
    x?: number;
    y?: number;
    scale?: number;
}

export interface FormatSet {
    title: Format['sets'][0];
    formats: Format[];
}

export enum FormatSortOrder {
    AlphabeticalAsc = 'alphabetical(asc)',
    AlphabeticalDes = 'alphabetical(des)',
    SizeAsc = 'size(asc)',
    SizeDes = 'size(des)',
    Position = 'position'
}

export default Format;
