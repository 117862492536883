import cloneDeepHelper from 'helpers/cloneDeep';

/**
 * This function clones a value without keeping the deep references that normal spread operator would have.
 * We use this custom function because the helper cloneDeep function is slower.
 * @param data - The data that has to be cloned.
 * @returns Cloned data without references.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cloneDeep = (data: any): any => {
    try {
        if (data === null || typeof data !== 'object' || typeof data === 'function') {
            // If the input is not an object, array, or function, return it as is
            return data;
        }

        if (Array.isArray(data)) {
            // If the input is an array, clone each element recursively
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return data.map((item) => cloneDeep(item)) as any;
        }

        if (typeof data === 'object') {
            // If the input is an object, clone each property recursively
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clonedData: Record<string, any> = {};
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    clonedData[key] = cloneDeep(data[key]);
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return clonedData as any;
        }

        // If the input is a primitive type (number, string, boolean), return it as is
        return data;
    } catch {
        return cloneDeepHelper(data);
    }
};

export default cloneDeep;
