/**
 * Round a number to the nearest multiple of another number.
 * @param number - Number to round.
 * @param roundTo - Number to round to.
 * @returns Rounded number.
 */
const roundToNearestNumber = (number: number, roundTo = 10): number => Math.round(number / roundTo) * roundTo;

/**
 * Round to the nearest decimal.
 * @param number - Number to round.
 * @param roundTo - Number to round to.
 * @returns Rounded number.
 */
const roundToNearestDecimal = (number: number, roundTo = 100): number => Math.round(number * roundTo) / roundTo;

export { roundToNearestNumber, roundToNearestDecimal };
