import { BrandSettingsColors } from 'types/brandSettings.type';
import Template from './template.type';

interface Color {
    type: ColorOptions;
    rotation: number;
    points: { location: number; color: { hex: Hex; rgb: Rgba } }[];
}

export interface BrandGuideColor extends BrandSettingsColors {
    brand: Template['templateData']['brand'];
    brandLabel?: string;
}

export enum ColorOptions {
    Transparent = 'transparent',
    Solid = 'solid',
    Linear = 'linear',
    Radial = 'radial'
}

export type Hex = string;
export interface Rgba {
    r: number;
    g: number;
    b: number;
    a?: number;
}
export interface Cmyk {
    c: number;
    m: number;
    y: number;
    k: number;
}

export default Color;
