import { DEFAULT_ZOOM_LEVEL } from '../constants';

/**
 * Calculate the box shadow for the selected layer.
 * @param zoom - Current zoom level.
 * @returns - Returns the box shadow for the selected layer.
 */
const getSelectedLayerBorder = (zoom?: number): string => {
    if (zoom === undefined) zoom = DEFAULT_ZOOM_LEVEL;
    return `rgb(41, 113, 235) 0px 0px 0px ${1 / zoom}px inset`;
};

export { getSelectedLayerBorder };
