/**
 * Checks if any text is selected.
 * @returns True if text is selected, false otherwise.
 */
const isTextSelected = (): boolean => {
    const selection = window.getSelection();
    return selection !== null && selection.toString().length > 0;
};

export { isTextSelected };
