import shortid from 'shortid';
import snakeCase from 'lodash/snakeCase';

/**
 * Generates a random key.
 * @param prefix - Optional prefix before the random id.
 * @returns Random key with optional prefix.
 */
const generateKey = (prefix = 'id_'): string => {
    const id = shortid().replaceAll('-', '_');
    return `${snakeCase(prefix)}_${id}`;
};

export { generateKey };
