/**
 * Deletes empty objects from the given object.
 * @param object - Object to delete empty objects from.
 */
const deleteObjectWhenEmpty = (object: object) => {
    for (const key in object) {
        if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
            deleteObjectWhenEmpty(object[key]);
            if (object[key] && Object.keys(object[key]).length === 0) {
                delete object[key];
            }
        }
    }
};

export { deleteObjectWhenEmpty };
