import * as Sentry from '@sentry/react';
import axios from 'axios';
import BrandSettings from 'types/brandSettings.type';
import Request from 'components/data/Request';
import User from 'components/data/User';
import Resources from 'components/data/Resources/components';
import {
    convertTemplateHistoryToAssetV2,
    convertTemplateHistoryToAssetV2List,
    convertTemplateToAssetV2
} from 'components/data/Templates/helpers/template-converter';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { isAMV2Enabled } from 'components/template-management/utilities';
import AssetManagementRequest from 'components/asset-management/data';
import AfterEffectsStructure from '../components/adobe/types/after-effects-structure.type';
import InDesignStructure from '../components/adobe/types/indesign-structure.type';
import {
    AdobeStructureResponse,
    CheckPreviewRenderResponse,
    CheckVideoRenderResponse,
    StartPreviewRenderResponse,
    StartVideoRenderResponse,
    TemplateHistoryResponse,
    ActivePeopleInTemplateResponse,
    TemplateDataResponseOld,
    TemplateDataVersionResponseOld,
    TemplateHistoryResponseOld
} from './template-designer.responses';
import Template from '../types/template.type';

class TemplateDesignerService {
    static RETRY_TIMES = 0;
    static AFTER_EFFECTS_TOKEN = 'AFTER_EFFECTS_TOKEN';
    static INDESIGN_TOKEN = 'INDESIGN_TOKEN';

    /**
     * Removes all Adobe tokens from the session storage.
     */
    static removeAdobeTokens(): void {
        sessionStorage.removeItem(this.AFTER_EFFECTS_TOKEN);
        sessionStorage.removeItem(this.INDESIGN_TOKEN);
    }

    /**
     * Gets the template designer template data
     * @param templateId - The id of the template.
     * @param version - The version of the template.
     * @returns The template designer template data.
     */
    static async getTemplateDesignerData(templateId: Template['id'], version?: number): Promise<TDTemplateAsset | undefined> {
        try {
            if (!isAMV2Enabled()) {
                if (version) {
                    const data = await this.getTemplateDataVersionOld(templateId, version.toString());
                    if (!data) return;
                    return convertTemplateHistoryToAssetV2(data) as TDTemplateAsset;
                }

                const data = await this.getTemplateDesignerDataOld(templateId);
                if (!data) return;
                return convertTemplateToAssetV2(data) as TDTemplateAsset;
            }

            let url = `asset/${templateId}`;
            if (version) url += `?versionNumber=${version}`;

            const response = await AssetManagementRequest.get(url);

            return response.data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the history of a template.
     * @param templateId - The id of the template.
     * @returns The history of the template.
     */
    static async getTemplateDataHistory(templateId: Template['id']): Promise<TemplateHistoryResponse[] | undefined> {
        try {
            if (!isAMV2Enabled()) {
                const data = await this.getTemplateDataHistoryOld(templateId);
                if (!data) return;
                return convertTemplateHistoryToAssetV2List(data);
            }

            const response = await AssetManagementRequest.get<TemplateHistoryResponse[]>(`asset/${templateId}/versions`);
            return response.data.reverse();
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the template designer template data
     * @deprecated For old templates.
     * @param templateId - The id of the template.
     * @returns The template designer template data.
     */
    static async getTemplateDesignerDataOld(templateId: string): Promise<TemplateDataResponseOld | undefined> {
        try {
            const response = await Request.post('templates/itemAsObject', { id: templateId });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Gets a specific verrsion.
     * @deprecated For old templates.
     * @param id - The id of the template.
     * @param versionId - The id of the version.
     * @returns InDesign structure response.
     */
    static async getTemplateDataVersionOld(id: string, versionId: string): Promise<TemplateDataVersionResponseOld | undefined> {
        try {
            const response = await Request.get(`templates/version?templateId=${id}&versionId=${versionId}`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the history of a template.
     * @deprecated For old templates.
     * @param templateId - The id of the template.
     * @returns The history of the template.
     */
    static async getTemplateDataHistoryOld(templateId: Template['id']): Promise<TemplateHistoryResponseOld[] | undefined> {
        try {
            const response = await Request.get(`templates/versions?templateId=${templateId}`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Saves template designer template data
     * @param templateId - The id of the template.
     * @returns The template designer template data.
     */
    static async saveTemplateDesignerData(templateId: Template['id'], template: unknown): Promise<TDTemplateAsset | undefined> {
        try {
            if (!isAMV2Enabled()) {
                await this.saveTemplateDesignerDataOld(templateId, template);
                return;
            }

            return (await AssetManagementRequest.patch(`asset/${templateId}`, template)).data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    /**
     * Saves template designer template data
     * @deprecated For old templates.
     * @param templateId - The id of the template.
     * @returns The template designer template data.
     */
    static async saveTemplateDesignerDataOld(templateId: Template['id'], template: unknown): Promise<void> {
        try {
            await Request.post('templates/save', { id: templateId, data: template });
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the colors and fonts in the brand settings with the current brand.
     * @param brand - Current template brand.
     * @returns {fonts, colors}
     */
    static async getBrandSettings(): Promise<BrandSettings | undefined> {
        try {
            const brandGuide = await Resources.load('brandSettings');

            if (!brandGuide) {
                return;
            }

            return brandGuide;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Gets active people to know if a warning message should shown.
     * @param templateId of the Template Designer template.
     * @returns Data related to active people in campaign.
     */
    static async getActivePeopleInTemplate(templateId: Template['id']): Promise<ActivePeopleInTemplateResponse | undefined> {
        try {
            const response = await Request.post('templates/getActivePeopleInTemplate', {
                id: templateId
            });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Gets a upload and download url for the media service.
     * @param fileName - The file name that will be uploaded.
     * @returns {{ uploadUrl, downloadUrl }} Upload url and download url.
     */
    static async getSignedUrls(fileName: string): Promise<{ uploadUrl: string; downloadUrl: string } | undefined> {
        try {
            const signedUrl = await axios.post(
                process.env.APP_MEDIA_URL + 'media/uploadtocloud',
                { filename: fileName },
                { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` } }
            );

            return {
                uploadUrl: signedUrl.data.uploadUrl,
                downloadUrl: signedUrl.data.url
            };
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    /**
     * Get a meta data token that is used for getting the structure of After Effects.
     * @returns Token for getting the structure for After Effects.
     */
    static async getAfterEffectsToken(): Promise<string | undefined> {
        try {
            const afterEffectsSessionToken = sessionStorage.getItem(this.AFTER_EFFECTS_TOKEN);

            if (afterEffectsSessionToken) {
                return afterEffectsSessionToken;
            }

            const {
                data: { token }
            } = await Request.post('/media/getAfterEffectsToken');

            if (!token) throw '401 no token found';
            sessionStorage.setItem(this.AFTER_EFFECTS_TOKEN, token);
            return token;
        } catch (error) {
            sessionStorage.removeItem(this.AFTER_EFFECTS_TOKEN);
            Sentry.captureException(error);
        }
    }

    /**
     * Get a meta data token that is used for getting the structure of InDesign.
     * @returns Token for getting the structure for InDesign.
     */
    static async getInDesignToken(): Promise<string | undefined> {
        try {
            const inDesignSessionToken = sessionStorage.getItem(this.INDESIGN_TOKEN);

            if (inDesignSessionToken) {
                return inDesignSessionToken;
            }

            const {
                data: { token }
            } = await Request.post('/media/getInDesignToken');

            if (!token) '401 no token found';
            sessionStorage.setItem(this.INDESIGN_TOKEN, token);
            return token;
        } catch (error) {
            sessionStorage.removeItem(this.INDESIGN_TOKEN);
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the After Effects structure from the uploaded file.
     * @param identifier - The identifier of the template.
     * @param downloadUrl - The url where the zip file can be downloaded from.
     * @param token - JWT token.
     * @returns After Effects structure response.
     */
    static async getAfterEffectsStructure(
        identifier: string,
        downloadUrl: string,
        token: string
    ): Promise<AdobeStructureResponse<AfterEffectsStructure> | undefined> {
        try {
            const response = await axios.post<AdobeStructureResponse<AfterEffectsStructure>>(
                `${process.env.AFTER_EFFECTS_URL}template/structure`,
                {
                    templateIdentifier: identifier,
                    templateUrl: downloadUrl
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            this.RETRY_TIMES = 0;
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 401: {
                        if (this.RETRY_TIMES >= 3) break;
                        this.RETRY_TIMES++;
                        sessionStorage.removeItem(this.AFTER_EFFECTS_TOKEN);
                        const token = await this.getAfterEffectsToken();
                        if (!token) break;
                        return await this.getAfterEffectsStructure(identifier, downloadUrl, token);
                    }
                }
            }
            Sentry.captureException(error);
        }
    }

    /**
     * Gets the InDesign structure from the uploaded file.
     * @param identifier - The identifier of the template.
     * @param downloadUrl - The url where the zip file can be downloaded from.
     * @param token - JWT token.
     * @returns InDesign structure response.
     */
    static async getInDesignStructure(identifier: string, downloadUrl: string, token: string): Promise<AdobeStructureResponse<InDesignStructure> | undefined> {
        try {
            const response = await axios.post<Promise<AdobeStructureResponse<InDesignStructure> | undefined>>(
                `${process.env.INDESIGN_URL}template/structure`,
                {
                    templateIdentifier: identifier,
                    templateUrl: downloadUrl
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            this.RETRY_TIMES = 0;
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 401: {
                        if (this.RETRY_TIMES >= 3) break;
                        this.RETRY_TIMES++;
                        sessionStorage.removeItem(this.INDESIGN_TOKEN);
                        const token = await this.getInDesignToken();
                        if (!token) break;
                        return await this.getInDesignStructure(identifier, downloadUrl, token);
                    }
                }
            }
            Sentry.captureException(error);
        }
    }

    /**
     * Check the preview render by calling /render/status/{uuid} endpoint.
     * @param uuid - id of the preview.
     * @param token - token to check the preview status.
     * @returns Status of the preview render.
     */
    static async checkPreviewRender(uuid: string, token: string): Promise<CheckPreviewRenderResponse | undefined> {
        try {
            const response = await axios.get(`${process.env.INDESIGN_URL}export/status/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    }

    /**
     * Check the video render by calling /render/status/{uuid} endpoint.
     * @param uuid - id of the video.
     * @param token - token to check the video status.
     * @returns Status of the video render.
     */
    static async checkVideoRender(uuid: string, token: string): Promise<CheckVideoRenderResponse | undefined> {
        try {
            const response = await axios.get(`${process.env.AFTER_EFFECTS_URL}render/status/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Start the video render by calling /render/start endpoint.
     * @param token - token to start the preview render.
     * @param data - data for the preview render.
     * @returns Status of the preview render.
     */
    static async startPreviewRender(token: string, data: object): Promise<StartPreviewRenderResponse | undefined> {
        try {
            const response = await axios.post(`${process.env.INDESIGN_URL}export/start`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    }

    /**
     * Start the video render by calling /render/start endpoint.
     * @param token - token to start the video render.
     * @param data - data for the video render.
     * @returns Status of the video render.
     */
    static async startVideoRender(token: string, data: object): Promise<StartVideoRenderResponse | undefined> {
        try {
            const response = await axios.post(`${process.env.AFTER_EFFECTS_URL}render/start`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    }

    /**
     * Start the legacy video render by calling /render/start endpoint.
     * @param token - token to start the video render.
     * @param data - data for the video render.
     * @returns Status of the video render.
     */
    static async startLegacyVideoRender(token: string, data: object): Promise<StartVideoRenderResponse | undefined> {
        try {
            const response = await axios.post(`${process.env.AFTER_EFFECTS_URL}render/legacy/start`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * cancel the video render by calling /render/cancel endpoint.
     * @param token - token to start the video render.
     * @param renderId - the id of the video render.
     * @returns Status of the video render.
     */
    static async cancelVideoRender(token: string, renderId: string): Promise<boolean> {
        try {
            await axios.post(`${process.env.AFTER_EFFECTS_URL}render/cancel?renderId=${renderId}`, undefined, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return true;
        } catch (error: unknown) {
            Sentry.captureException(error);
            return false;
        }
    }

    /**
     * Get local file
     * @returns Local file.
     */
    static async getLocalFile(url: string): Promise<object | undefined> {
        try {
            const response = await axios.get<object>(url);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}

export default TemplateDesignerService;
