import { Attributes } from './attribute.type';
import Layer from './layer.type';

export interface BaseInput {
    key: string;
    type: 'group' | 'divider' | 'feedSelectorInput' | 'alert' | 'dropdown' | 'field' | 'customInput';
    overwriteModel?: string;
    label: string;
    helperText?: string;
    tooltip?: string;
    attribute: Attributes;
    settings: AllSettings;
    condition?: AllConditions[][] | string; // Each input can have multiple condition blocks that are in the first array. Each block consists of an array of one or more conditions. If all conditions in one block are true the condition is true.
    frame?: string; // Used for After Effects.
    frameName?: string; // Used for After Effects.
}

export interface GroupInput extends BaseInput {
    type: 'group';
    children: DynamicLayerInput[];
    settings: GroupSettings;
}

export interface DividerInput extends BaseInput {
    type: 'divider';
    settings: DividerSettings;
}

export interface FeedItemSelectorInput extends BaseInput {
    type: 'feedSelectorInput';
    settings: FeedItemSelectorSettings;
}

export interface AlertInput extends BaseInput {
    type: 'alert';
    settings: AlertSettings;
}

export interface DropdownInput extends BaseInput {
    type: 'dropdown';
    settings: RadioListSettings | SelectSettings;
}

export interface FieldInput extends BaseInput {
    type: 'field';
    layerKey: Layer['key'];
    children: DynamicLayerInput[];
    settings: AllSettings;
}

export interface CustomInput extends BaseInput {
    type: 'customInput';
    settings: AllSettings;
}

export interface BaseSettings {
    type: string;
    readOnly?: boolean;
    languageSpecific?: boolean;
}

export interface GroupSettings extends BaseSettings {
    type: 'switch';
    opened?: boolean;
}

export interface DividerSettings extends BaseSettings {
    type: 'divider';
}

export interface AlertSettings extends BaseSettings {
    type: 'alert';
    alertType: 'info' | 'warning' | 'error';
    message: string;
}

export type DynamicLayerInput = GroupInput | DividerInput | FeedItemSelectorInput | AlertInput | DropdownInput | FieldInput | CustomInput;

export interface FeedItemSelectorSettings extends BaseSettings {
    type: 'feedItemSelector';
    display: 'grid' | 'list';
    usage: 'locked' | 'custom';
    compact?: boolean;
    feedTitle?: string;
}

export interface SwitchSettings extends BaseSettings {
    type: 'switch';
    opened?: boolean;
    isVisible?: boolean;
    inverted?: boolean;
    defaultValue?: boolean;
}

export interface SliderSettings extends BaseSettings {
    type: 'slider';
    endAdornment?: string;
    min: number;
    max: number;
    defaultValue?: number;
    step: number;
    hideMarks?: boolean;
    inputFieldDisplay: boolean;
    marks: boolean;
    allowZero: boolean;
}

export interface ColorSettings extends BaseSettings {
    type: 'color';
    picker: ColorSettingsPicker;
    useGradients?: boolean;
    colors?: string[];
}

export interface AssetGalleryInputSettings extends BaseSettings {
    type: 'assetGalleryInput';
    fileType: ('image' | 'video' | 'audio' | '.gif')[];
    canOutpaint?: boolean;
    canUseImageToVideo?: boolean;
    canUseContentSpace?: boolean;
    contentSpaceCollection?: string;
    contentSpaceCollectionQuery?: string;
    canUseUrl?: boolean;
    canUseArimo?: boolean;
    aprimoTenant?: string;
    aprimoOutputFormat?: 'jpg' | 'png';
    canRemoveBackground?: boolean;
    canRemoveObjects?: boolean;
    canMagicEdit?: boolean;
    useCustomCompressor?: boolean;
    canUseVoiceOverGenerator?: boolean;
    canSkipCropper?: boolean;
    canSkipCompressor?: boolean;
    imageFormat?: 'jpg' | 'png';
    mode?: 'free' | 'fixed';
    outpaintMode?: 'free' | 'fixed';
    maxOutputWidth?: number;
    maxOutputHeight?: number;
    outputWidth?: number;
    outputHeight?: number;
    canUpload?: boolean;
    canUseAiContent?: boolean;
    canUseUnsplash?: boolean;
    canUseAprimo?: boolean;
    useCropper?: boolean;
    cropMode?: 'sizeBased' | 'ratioBased';
    useCompressor?: boolean;
    isBackgroundImage?: boolean;
    useDurationOfLayer?: boolean;
    miniumDuration?: number;
    maximumDuration?: number;
    ratios?: string[];
}

export interface AssetSelectorSettings extends BaseSettings {
    type: 'assetSelector';
    fileType: 'image';
    canUpload: boolean;
    showTitles: boolean;
    sourceData: {
        id: string;
        url: string;
        title: string;
    }[];
}

export interface FilesInputSettings extends BaseSettings {
    type: 'files';
    fileType: string[];
    multiple: boolean;
    canUpload: boolean;
}

export interface HiddenSettings extends BaseSettings {
    type: 'hidden';
    parseValue: string;
}

export interface TextSettings extends BaseSettings {
    type: 'text' | 'textMultiLanguage';
    placeholder: string;
    maxLength?: number;
    multiline?: boolean;
    useValueObject?: boolean;
    sidekickAssistant?: boolean;
}

export interface TextSelectSettings extends BaseSettings {
    type: 'textSelect' | 'textSelectMultiLanguage';
    options: {
        key: string;
        value: string;
    }[];
    selectType: 'select' | 'radioList';
    multiLanguage?: boolean;
    sortOptions?: boolean;
    setFirstOption?: boolean;
    showEmptyOption?: boolean;
    multiline?: boolean;
    maxLength?: number;
}

export interface NumberSettings extends BaseSettings {
    type: 'number';
    min?: number;
    max?: number;
    step?: number;
    placeholder: string;
}

export interface RadioListSettings extends BaseSettings {
    type: 'radioList' | 'radioListMultiLanguage';
    endAdornment?: string;
    sortOptions?: boolean;
    options: {
        key: string;
        value: string;
    }[];
}

export interface SelectSettings extends BaseSettings {
    type: 'select' | 'selectMultiLanguage';
    sortOptions?: boolean;
    showEmptyOption?: boolean;
    setFirstOption?: boolean;
    endAdornment?: string;
    options: {
        key: string;
        value: string;
    }[];
}

export interface ButtonGroupSettings extends BaseSettings {
    type: 'buttonGroup';
    options: {
        key: string;
        value: string;
    }[];
}

export enum ColorSettingsPicker {
    Predefined = 'predefined',
    PredefinedCustom = 'predefinedCustom',
    Compact = 'compact',
    Picker = 'picker'
}

export type AllSettings =
    | GroupSettings
    | DividerSettings
    | AlertSettings
    | FeedItemSelectorSettings
    | SwitchSettings
    | SliderSettings
    | ColorSettings
    | AssetGalleryInputSettings
    | AssetSelectorSettings
    | FilesInputSettings
    | HiddenSettings
    | TextSettings
    | TextSelectSettings
    | NumberSettings
    | RadioListSettings
    | SelectSettings
    | ButtonGroupSettings;

export type DefaultSettingsFunction<ReturnSettings> = {
    (selectedLayer: Layer, attribute: Attributes): ReturnSettings;
};

export type updateSettingsFunction = {
    (selectedLayer: Layer, attribute: Attributes, oldSettings: AllSettings): AllSettings;
};

export type SelectInputTypes = Exclude<AllSettings['type'], 'select' | 'radioList'> | 'multiSelect';

export interface BaseCondition {
    type: string;
    operator: ConditionOperator;
    valueToCompare?: string;
}

export enum ConditionType {
    Field = 'field',
    UserType = 'userType',
    UserLevel = 'userLevel',
    UserMarket = 'userMarket',
    UserBrand = 'userBrand',
    UserDepartment = 'userDepartment',
    Language = 'language',
    Market = 'market',
    EditorType = 'editorType'
}

export enum ConditionOperator {
    IsSet = 'isSet',
    IsNotSet = 'isNotSet',
    Equals = '===',
    NotEquals = '!==',
    GreaterThan = '>',
    LessThan = '<',
    Includes = 'includes',
    DoesNotInclude = 'doesNotInclude'
}

export interface FieldCondition extends BaseCondition {
    type: ConditionType.Field;
    operator:
        | ConditionOperator.IsSet
        | ConditionOperator.IsNotSet
        | ConditionOperator.Equals
        | ConditionOperator.NotEquals
        | ConditionOperator.LessThan
        | ConditionOperator.GreaterThan
        | ConditionOperator.Includes
        | ConditionOperator.DoesNotInclude;
    fieldKey: BaseInput['key'];
    valueToCompare?: string;
}

export interface UserTypeCondition extends BaseCondition {
    type: ConditionType.UserType;
    operator: ConditionOperator.Equals | ConditionOperator.NotEquals;
    valueToCompare: string;
}

export interface UserMarketCondition extends BaseCondition {
    type: ConditionType.UserMarket;
    operator: ConditionOperator.Includes | ConditionOperator.DoesNotInclude;
    valueToCompare: string;
}

export interface UserBrandCondition extends BaseCondition {
    type: ConditionType.UserBrand;
    operator: ConditionOperator.Includes | ConditionOperator.DoesNotInclude;
    valueToCompare: string;
}

export interface UserDepartmentCondition extends BaseCondition {
    type: ConditionType.UserDepartment;
    operator: ConditionOperator.Includes | ConditionOperator.DoesNotInclude;
    valueToCompare: string;
}

export interface UserLevelCondition extends BaseCondition {
    type: ConditionType.UserLevel;
    operator: ConditionOperator.Equals | ConditionOperator.NotEquals | ConditionOperator.LessThan | ConditionOperator.GreaterThan;
    valueToCompare: string;
}

export interface LanguageCondition extends BaseCondition {
    type: ConditionType.Language;
    operator: ConditionOperator.Equals | ConditionOperator.NotEquals;
    valueToCompare: string;
}

export interface MarketCondition extends BaseCondition {
    type: ConditionType.Market;
    operator: ConditionOperator.Equals | ConditionOperator.NotEquals;
    valueToCompare: string;
}

export interface EditorTypeCondition extends BaseCondition {
    type: ConditionType.EditorType;
    operator: ConditionOperator.Equals | ConditionOperator.NotEquals;
    valueToCompare: string;
}

export type AllConditions =
    | FieldCondition
    | UserTypeCondition
    | UserLevelCondition
    | UserMarketCondition
    | UserBrandCondition
    | UserDepartmentCondition
    | LanguageCondition
    | MarketCondition
    | EditorTypeCondition;

export type ConflictingCases = {
    editorEquals: boolean;
    editorNotEquals: boolean;
    languageEquals: boolean;
    languageNotEquals: boolean;
    userLevelEquals: boolean;
    userLevelNotEquals: boolean;
    userTypeEquals: boolean;
    userTypeNotEquals: boolean;
    marketEquals: boolean;
    marketNotEquals: boolean;
};
